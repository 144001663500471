import { createContext, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { currentCulture as currentCultureFromStore } from 'application/adapters/store/slices/cultureSlice';
import { Footer } from 'ui/components/4-habitats/Footer';
import { useRouter } from 'next/router';
import { DictionaryContext } from 'application/adapters/context/Dictionary';
import { LocaleContext } from 'application/adapters/context/LocaleContext';

interface FooterFeatureProps {
	culture: Umbraco.Cultures;
	pageFooter: Umbraco.FooterCard[];
	contactLink: Umbraco.Link;
	languages?: Umbraco.LanguageSelector;
	locations: Umbraco.Locations;
	socialLinks: Umbraco.SocialLink[];
}

export const FooterFeature: React.FC<FooterFeatureProps> = ({
	culture,
	pageFooter,
	contactLink,
	locations,
	languages,
	socialLinks,
}) => {
	const router = useRouter();
	const dictionary = useContext(DictionaryContext);
	const { offices } = locations?.locations?.pages[0]?.properties ?? {};
	const footerLanguageSelectorProps =
		languages?.languageSelector?.pages[0]?.properties ?? {};
	const footerDictionary = {
		footerAddressHeading: dictionary.getValue(
			'Footer.AddressHeading',
			null,
			'Company',
		),
	};
	const localeContext = useContext(LocaleContext);
	const localeCountry = localeContext.getLocaleCountry();
	const localeLanguage = localeContext.getLocaleLanguage();

	const handleLanguageChange = (language: string, country: string) => {
		localeContext.setLocaleParams(language, country);
		setSelectedLocale(localeContext.formatLanguage(language, country));
	};

	// Initial language
	const initialLanguage = localeContext.getLanguageFromCookie() ?? {
		language: router.locale,
		country: null,
	};

	const [selectedLocale, setSelectedLocale] = useState({
		...initialLanguage,
	});

	// the locale is set in the cookie and handled in the locale context
	useEffect(() => {
		handleLanguageChange(localeLanguage, localeCountry);
		setSelectedLocale(
			localeContext.formatLanguage(localeLanguage, localeCountry),
		);
	}, [localeLanguage, localeCountry]);

	return (
		<Footer
			footerCards={pageFooter}
			offices={offices}
			contactLink={contactLink}
			languageSelectorProps={footerLanguageSelectorProps}
			selectedLocale={
				selectedLocale?.country
					? `${selectedLocale?.language} / ${selectedLocale?.country}`
					: selectedLocale?.language
			}
			socialLinksItems={socialLinks}
			culture={router.locale}
			dictionary={footerDictionary}
			handleCountrySelection={handleLanguageChange}
		/>
	);
};
